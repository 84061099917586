
import JwtService from '@/@core/auth/jwt/jwtService'

export default function checkToken(axiosIns) {
    let data = JSON.parse(localStorage.getItem('userData'))
	if(!data) return

    checkingTheDateAndTime(data)

    setInterval(() => {
        checkingTheDateAndTime(data)
    }, 30000)
    
	setInterval(() => {
		let datas = JSON.parse(localStorage.getItem('userData'))
		refreshToken(axiosIns, datas)
	}, data.access_token_expires * 1000 / 2)
}

function refreshToken(axiosIns, data) {
    let formData = new FormData()
    formData.append('refresh_token', data.refresh_token)

    axiosIns.post(`auth/refresh-token/${data.id}`, formData, {
        headers: {
            'Content-Type': 'multipart/form-data'
        },
    })
    .then((res) => {
        let userData = JSON.parse(localStorage.getItem('userData'))
        userData.access_token = res.data.access_token
        userData.access_token_expires = res.data.expires_in
        userData.refresh_token = res.data.refresh_token
        localStorage.setItem('userData', JSON.stringify(userData)) 

		const jwtService = new JwtService(axiosIns)

		jwtService.setToken(res.data.access_token)
    	jwtService.setRefreshToken(res.data.access_token)

        axiosIns.defaults.headers.common['Authorization'] = `Bearer ${res.data.access_token}`
    })
    .catch((error) => {
        console.log(error);
        
    })
}


function checkingTheDateAndTime(data) {
    let dateStart = new Date(data.access_token_expDate)
    let dateEnd = new Date()


    let start = `${String(dateStart.getHours()).length == 2 ? dateStart.getHours() : '0' + dateStart.getHours()}:${String(dateStart.getMinutes()).length == 2 ? dateStart.getMinutes() : '0' + dateStart.getMinutes()}`
    let end = `${String(dateEnd.getHours()).length == 2 ? dateEnd.getHours() : '0' + dateEnd.getHours()}:${String(dateEnd.getMinutes()).length == 2 ? dateEnd.getMinutes() : '0' + dateEnd.getMinutes()}`
    
    
    if(dateEnd.getDate() == dateStart.getDate()) {
        if(end >= start) {
            localStorage.removeItem('accessToken')
            localStorage.removeItem('userData')
            location.reload()
        }
    }else {
        localStorage.removeItem('accessToken')
        localStorage.removeItem('userData')
        location.reload()
    }
}